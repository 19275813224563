<template>
  <b-card-actions
    ref="cardA"
    no-actions
    no-body
  >
    <b-card no-body>
      <!-- !! Table Header -->
      <div class="mx-1 mt-1">
        <b-row>
          <b-col class="mt-1" cols="12" md="4" lg="2">
            <HeroVueSelect
              id="type"
              v-model="type"
              placeholder="Select Lead Type"
              :clearable="false"
              :options="typeOptions"
            />
          </b-col>
          <b-col class="mt-1" cols="12" md="4" lg="3">
            <HeroVisionCampaignSelect
              id="campaign-id"
              v-model="campaignId"
              placeholder="Select Campaign"
              :clearable="true"
              :options="campaignOptions"
            />
          </b-col>
          <b-col class="mt-1" cols="12" md="4" lg="3">
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              @input="doTableFilter(500)"
            />
          </b-col>
          <b-col class="mt-1" cols="6" md="4" lg="2">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="doTableFilter(100)"
            />
          </b-col>
        </b-row>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="doTableSort"
          >
            <template #cell(submitted_date_time)="data">
              {{ formatEnglishDateTimeDefault(data.item.submitted_date_time) }}
            </template>

            <template #cell(campaign_name)="data">
              <div v-if="type == 'blacklist'">
                {{ data.item.campaign_name }}
              </div>
              <div v-if="type == 'block' && campaignId">
                {{ getCampaignNameFromId(campaignId) }}
              </div>
              <div v-if="type == 'block' && !campaignId">
                {{ data.item.campaign_name }}
              </div>
            </template>

            <template #cell(content)="data">
              <div
                v-b-popover.hover.html="popoverCustomerAttribute(JSON.parse((data.item.form_customer_attribute)))"
                class="cursor-pointer"
                no-fade
              >
                {{ formatCustomerAttribute(JSON.parse(data.item.form_customer_attribute)) }}
              </div>
            </template>

            <template #cell(source_name)="data">
              {{ data.item.source_name }}
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card-actions>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import {
  BCard,
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroVisionCampaignSelect from '@/views/components/form/selects/HeroVisionCampaignSelect.vue'
import SweetAlert from '@/services/SweetAlert'
import DataFormatService from '@/services/DataFormatService'
import LeadManagement from '@/views/herovision/LeadManagement.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BCardActions,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    HeroVueSelect,
    HeroVisionCampaignSelect,
  },
  data() {
    return {
      type: 'blacklist',
      campaignId: '',

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'submitted_date_time',
        sortDirection: 'desc',
        timeInterval: moment(),
        fields: [
          {
            label: 'submitted time',
            key: 'submitted_date_time',
            sortable: true,
          },
          {
            label: 'analytic campaign id',
            key: 'analytic_campaign_id',
            sortable: true,
          },
          {
            label: 'campaign name',
            key: 'campaign_name',
            sortable: false,
          },
          {
            label: 'first name',
            key: 'form_first_name',
            sortable: true,
          },
          {
            label: 'last name',
            key: 'form_last_name',
            sortable: true,
          },
          {
            label: 'email',
            key: 'form_email',
            sortable: true,
          },
          {
            label: 'phone',
            key: 'form_phone',
            sortable: true,
          },
          {
            label: 'content',
            key: 'content',
            sortable: false,
          },
          {
            label: 'source name',
            key: 'source_name',
            sortable: false,
          },
        ],
      },

      // Dropdown Options
      campaignOptions: [],
      typeOptions: [
        { text: 'Blacklist', value: 'blacklist' },
        { text: 'Spam, Block, Test', value: 'block' },
      ],

      // Import Methods
      formatEnglishDateTimeDefault: DataFormatService.formatEnglishDateTimeDefault,
      popoverCustomerAttribute: LeadManagement.methods.popoverCustomerAttribute,
      formatCustomerAttribute: LeadManagement.methods.formatCustomerAttribute,
    }
  },
  watch: {
    async campaignId() {
      await this.doLoadData()
    },
    async type() {
      await this.doLoadData()
    },
  },
  async mounted() {
    await this.getCampaignOptions()
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    // โหลดข้อมูลแคมเปญ
    async getCampaignOptions() {
      try {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const response = await axios.get('dropdown-options/herovision-campaign-options?orderByColumn=name&orderByDirection=asc', axiosConfig)

        if (response.data.data) {
          this.campaignOptions = response.data.data.map(campaign => ({
            text: campaign.name,
            value: campaign.id,
            campaign_status_id: campaign.campaign_status_id,
          }))
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },

    async doLoadData() {
      this.$refs.cardA.showLoading = true

      const axiosConfig = AxiosConfig.getLeadManagementConfig()

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const payload = {
          type: this.type,
          campaignId: this.campaignId,
          query: filter,
          perPage,
          page: currentPage,
          orderByColumn: sortBy,
          orderByDirection: sortDirection,
        }

        const url = `${process.env.VUE_APP_LEAD_SERVICE_API}blacklist-leads`
        const response = await axios.post(url, payload, axiosConfig)

        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)

        if (this.type === 'block' && !this.campaignId) {
          this.items = await Promise.all(response.data.data.data.map(async lead => {
            const campaignName = await this.getCampaignNameFromHeroAIX(lead.campaign_id)
            const productName   = await this.getProductNameFromHeroAIX(lead.channel_id)

            return {
              ...lead,
              campaign_name: campaignName,
              source_name: productName,
            }
          }))
        } else {
          this.items = await Promise.all(response.data.data.data.map(async lead => {
            const productName = await this.getProductNameFromHeroAIX(lead.channel_id)

            return {
              ...lead,
              source_name: productName,
            }
          }))
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        this.tableConfig.totalRows = 0
      }

      this.$refs.cardA.showLoading = false
    },

    getCampaignNameFromId(campaignId) {
      const campaign = this.campaignOptions.find(object => object.value === campaignId)

      if (campaign) {
        return campaign.text
      }

      return '-'
    },

    async getCampaignNameFromHeroAIX(campaignId) {
      if (campaignId) {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const url = `campaigns/${campaignId}/name`
        const response = await axios.get(url, axiosConfig)

        return response.data.campaignName
      }

      return '-'
    },

    async getProductNameFromHeroAIX(channelId) {
      if (channelId) {
        const token = store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const params = {
          channel_id: channelId,
        }

        const config = { params, ...axiosConfig }

        const url = 'lead-service/get-product-name'
        const response = await axios.get(url, config)

        return response.data.product_name
      }

      return '-'
    },
  },
}
</script>
